import { BatchUserOperationCallData } from '@alchemy/aa-core'
import { MAX_INT, WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { SECONDS_IN_DAY } from '@lyra/core/constants/time'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import { Address, encodeFunctionData } from 'viem'

import delegateErc20Abi from '../abis/delegateErc20Abi'
import erc20Abi from '../abis/erc20Abi'
import rewardsDistributorAbi from '../abis/rewardsDistributorAbi'
import claimAbi from '../abis/tgeClaimAbi'
import { lyraClient } from '../constants/client'
import { lyraContractAddresses } from '../constants/contracts'
import { ClaimAirdropOptions, DrvStats } from '../constants/drv'
import { TokenId } from '../constants/tokens'
import { getLyraTokenAddress } from './tokens'

// Vest for 0 days - 20% penalty
const INSTANT_UNSTAKE_VEST_DURATION_SEC = 0
// Max vest (28 days) - no penalty
const UNSTAKE_VEST_DURATION_SEC = 28 * SECONDS_IN_DAY

const TOTAL_DELEGATE_SHARE = 10_000

// 20% haircut
export const getUnstakePenaltyAmount = (amount: bigint) => {
  return (amount * BigInt(4)) / BigInt(5)
}

export const getPreUnstakePenaltyAmount = (amount: bigint) => {
  return (amount * BigInt(5)) / BigInt(4)
}

export const getMigrationClaimFromScwTx = (
  ownerAddress: Address,
  {
    drvClaimAmount = BigInt(0),
    stDrvClaimAmount = BigInt(0),
  }: {
    stDrvClaimAmount?: bigint
    drvClaimAmount?: bigint
  }
): BatchUserOperationCallData[0] => {
  console.debug('getMigrationClaimFromScwTx', {
    drvClaimAmount,
    stDrvClaimAmount,
  })
  return {
    target: lyraContractAddresses.migrationClaim,
    data: encodeFunctionData({
      abi: claimAbi,
      functionName: 'claimFromSCW',
      args: [ownerAddress, drvClaimAmount, stDrvClaimAmount],
    }),
  }
}

export const getDistributorClaimTx = (): BatchUserOperationCallData[0] => {
  return {
    target: lyraContractAddresses.rewardsDistributor,
    data: encodeFunctionData({
      abi: rewardsDistributorAbi,
      functionName: 'claimAll',
      args: [],
    }),
  }
}

export const getClaimAirdropTx = async (
  address: Address,
  options: ClaimAirdropOptions
): Promise<{ tx: BatchUserOperationCallData[0]; amount: bigint }> => {
  console.debug('getClaimAirdropTx', {
    options,
  })

  const claimsByToken = await lyraClient.readContract({
    abi: rewardsDistributorAbi,
    address: lyraContractAddresses.rewardsDistributor,
    functionName: 'getAvailableClaimsByToken',
    args: [address],
  })
  const claimableStDrvBalance =
    claimsByToken.find(
      (claim) => claim.token.toLowerCase() === getLyraTokenAddress(TokenId.STDRV).toLowerCase()
    )?.amount ?? BigInt(0)

  return {
    tx: getDistributorClaimTx(),
    amount: claimableStDrvBalance,
  }
}

export const getUnstakeTx = (
  amount: bigint,
  { isInstant }: { isInstant: boolean }
): BatchUserOperationCallData[0] => {
  return {
    target: getLyraTokenAddress(TokenId.STDRV),
    data: encodeFunctionData({
      abi: delegateErc20Abi,
      functionName: 'redeem',
      args: [
        amount,
        isInstant ? BigInt(INSTANT_UNSTAKE_VEST_DURATION_SEC) : BigInt(UNSTAKE_VEST_DURATION_SEC),
      ],
    }),
  }
}

export const getCancelUnstakeTx = (redeemIndex: number): BatchUserOperationCallData[0] => {
  return {
    target: getLyraTokenAddress(TokenId.STDRV),
    data: encodeFunctionData({
      abi: delegateErc20Abi,
      functionName: 'cancelRedeem',
      args: [BigInt(redeemIndex)],
    }),
  }
}

export const getFinalizeUnstakeTx = (redeemIndex: number): BatchUserOperationCallData[0] => {
  return {
    target: getLyraTokenAddress(TokenId.STDRV),
    data: encodeFunctionData({
      abi: delegateErc20Abi,
      functionName: 'finalizeRedeem',
      args: [BigInt(redeemIndex)],
    }),
  }
}

export const getStakeApprovalTx = () => {
  return {
    target: getLyraTokenAddress(TokenId.DRV),
    data: encodeFunctionData({
      abi: erc20Abi,
      functionName: 'approve',
      args: [getLyraTokenAddress(TokenId.STDRV), MAX_INT],
    }),
  }
}

export const getStakeTx = (amount: bigint): BatchUserOperationCallData[0] => {
  return {
    target: getLyraTokenAddress(TokenId.STDRV),
    data: encodeFunctionData({
      abi: delegateErc20Abi,
      functionName: 'convert',
      args: [amount],
    }),
  }
}

// !!IMPORTANT delegation is to EOA _not_ SCW
export const getDelegateTx = (delegates: Address[]): BatchUserOperationCallData[0] => {
  const delegateSet = new Set(delegates)
  const sortedDelegates = Array.from(delegateSet).sort((a, b) => parseInt(a, 16) - parseInt(b, 16))
  const delegatesArg = sortedDelegates.map((delegate) => ({
    _delegatee: delegate,
    _numerator: BigInt(Math.floor(TOTAL_DELEGATE_SHARE / delegates.length)),
  }))
  return {
    target: getLyraTokenAddress(TokenId.STDRV),
    data: encodeFunctionData({
      abi: delegateErc20Abi,
      functionName: 'delegate',
      args: [delegatesArg],
    }),
  }
}

export const fetchDrvStats = async (): Promise<DrvStats> => {
  const [drvTotalSupply, stDrvTotalSupply] = await Promise.all([
    lyraClient.readContract({
      abi: erc20Abi,
      address: getLyraTokenAddress(TokenId.DRV),
      functionName: 'totalSupply',
      args: [],
    }),
    lyraClient.readContract({
      abi: erc20Abi,
      address: getLyraTokenAddress(TokenId.STDRV),
      functionName: 'totalSupply',
      args: [],
    }),
  ])

  return {
    drvTotalSupply: bigNumberToNumberUNSAFE(drvTotalSupply, WEI_DECIMALS),
    stDrvTotalSupply: bigNumberToNumberUNSAFE(stDrvTotalSupply, WEI_DECIMALS),
  }
}
