import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tamagui/core/reset.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/app/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/tamagui.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/page_helpers/AppLayoutHelper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/AdminProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/AuthProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/IntercomProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/LocalStorageProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/MarketProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/NotificationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/OrderbookTimeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/SubaccountProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/ThemeProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/TickerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/TransactionProvider/EoaProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/TransactionProvider/TransactionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/VercelAnalyticsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/WalletProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/providers/WebSocketProvider.tsx");
