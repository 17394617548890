import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { SECONDS_IN_DAY } from '@lyra/core/constants/time'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import { Address } from 'viem'

export type DrvStats = {
  drvTotalSupply: number
  stDrvTotalSupply: number
}

export type ClaimAirdropOptions =
  | {
      isInstantUnstake: true
      delegates?: never
    }
  | {
      isInstantUnstake?: false
      delegates?: Address[]
    }

export type ClaimMigrationDrvOptions = {
  amount: bigint
  delegates?: Address[]
  isStake?: boolean
}

export type PendingUnstake = {
  drvAmount: bigint
  stDrvAmount: bigint
  endTimestamp: number
  redeemIndex: number
}

// Jan 15 00:00 GMT
export const AIRDROP_DATE_MS = 1736899200000
export const DELAYED_CLAIM_BALANCE_THRESHOLD = toBigNumber(5, WEI_DECIMALS)
export const DELAYED_AIRDROP_CLAIM_DATE_MS = AIRDROP_DATE_MS + SECONDS_IN_DAY * 1000

type EpochData = {
  levelLabel: string
  dropDrv: string
  levelDrv: string
}

export type AirdropSnapshotBalance = {
  [key: string]: EpochData
}
//  & {
//   misc?: {
//     sENA?: string
//     // Share of a pool of DRV for users that lost to slippage by holding LP positions during the Lyra snapshot
//     snap_lp?: string
//     // For depositors to vault LP programs
//     vault?: string
//     Loyalty?: string
//     Ethena?: string
//     Hyperliquid?: string
//     EtherFi?: string
//   }
// }

// All strings in 10^18
export type AirdropSnapshotBalancesWithTotal = {
  epochs: AirdropSnapshotBalance
  total: string
}

// All strings in 10^18
export type MigrationSnapshotBalance = {
  snapshotDRV: string
  snapshotStDRV: string
  prestakeBonus?: string
  holderPointBonus?: string
}

export type MigrationSnapshotBalanceWithTotal = MigrationSnapshotBalance & {
  total: string
}
