import Modal, { ModalControlProps } from '@lyra/core/components/Modal'
import { NoticeProps } from '@lyra/core/components/Notice'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import { KytError } from '@lyra/web/constants/auth'
import { LoggerTransaction } from '@lyra/web/constants/logger'
import { Pathname } from '@lyra/web/constants/pages'
import {
  BoundWalletTransaction,
  TransactionStatus,
  TransactionStatusContext,
} from '@lyra/web/constants/transactions'
import { formatDepositNetworkName, getDepositNetworkForChainId } from '@lyra/web/utils/chains'
import { logDatadogError } from '@lyra/web/utils/logger'
import { useModalStatus as usePrivyModalStatus } from '@privy-io/react-auth'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ProviderRpcError, UserRejectedRequestError } from 'viem'

import TransactionButtonRowInternal from '../TransactionButtonRowInternal'
import WalletTransactionProgressScreen from './WalletTransactionProgressScreen'

type Props = {
  onClose: () => void
  // TODO: @earthtojake rename handleStartTransaction
  transaction: BoundWalletTransaction
  reviewTitle: React.ReactNode
  reviewButtonLabel?: string
  retryButtonLabel?: string
  reviewSubtitle?: string
  reviewContent?: React.ReactNode
  isAutoExecute?: boolean
  loggerAction: LoggerTransaction
  onPressComplete?: () => void
  isDisabled?: boolean
  completeButtonProps?: {
    label: string
    path: Pathname
  }
  onTransactionComplete?: () => void
  notice?: NoticeProps
  skipGeoblockCheckDONOTUSE?: boolean
} & ModalControlProps

const getDefaultTitle = (status: TransactionStatus, context?: TransactionStatusContext): string => {
  const network = context?.chain ? getDepositNetworkForChainId(context.chain.id) : undefined
  switch (status) {
    case 'switch-network':
      return network ? `Switch To ${formatDepositNetworkName(network)} Network` : 'Switch Network'
    case 'paymaster':
    case 'confirm':
      return 'Confirm Transaction'
    case 'complete':
      return 'Transaction Successful'
    case 'in-progress':
      return 'Transaction In Progress'
    case 'verify':
      return 'Verifying Signature'
    case 'auto-execute':
      return 'Preparing Transaction'
  }
}

const getInstructionStr = (txStatus: TransactionStatus): string | null => {
  switch (txStatus) {
    case 'paymaster':
      return 'Preparing wallet signature. This can take a few seconds.'
    case 'switch-network':
      return 'Network switch required. Proceed in your wallet.'
    case 'confirm':
      return 'Signature required. Proceed in your wallet.'
    case 'in-progress':
      return 'Your transaction is in progress.'
    case 'verify':
      return 'Your signature is being verified.'
    case 'auto-execute':
    case 'complete':
      return null
  }
}

export default function WalletTransactionModal({
  reviewTitle,
  reviewSubtitle,
  reviewButtonLabel = 'Submit',
  retryButtonLabel = 'Retry',
  isAutoExecute,
  completeButtonProps,
  isDisabled,
  reviewContent,
  notice,
  loggerAction,
  skipGeoblockCheckDONOTUSE,
  transaction,
  onPressComplete,
  onTransactionComplete,
  onClose,
  ...modalControlProps
}: Props) {
  const { isOpen: isPrivyModalOpen } = usePrivyModalStatus()
  const hasAutoExecuted = useRef(false)
  const [disableClose, setDisableClose] = useState(modalControlProps.disableClose)
  const [txStatus, setTxStatus] = useState<TransactionStatus | undefined>(
    // Note: auto-execute is a special status used to automatically trigger a tx on modal mount
    isAutoExecute ? 'auto-execute' : undefined
  )
  const [txStatusContext, setTxStatusContext] = useState<TransactionStatusContext>()
  const [error, setError] = useState<string | null>(null)

  const updateTransactionStatus = useCallback(
    (newTransactionStatus: TransactionStatus | null, context?: TransactionStatusContext) => {
      if (newTransactionStatus === 'complete' && onTransactionComplete) {
        onTransactionComplete()
      }
      setTxStatus(newTransactionStatus ?? undefined)
      setTxStatusContext(context)
      setDisableClose(
        newTransactionStatus === 'in-progress' ||
          newTransactionStatus === 'confirm' ||
          newTransactionStatus === 'verify'
      )
    },
    [onTransactionComplete]
  )

  const handleSubmitTransaction = useCallback(async () => {
    setError(null)
    try {
      await transaction({
        onTransactionStatusChange: updateTransactionStatus,
      })
      if (onTransactionComplete && txStatus === 'complete') {
        onTransactionComplete()
      }
    } catch (e) {
      console.error(e)
      logDatadogError(e, loggerAction)
      updateTransactionStatus(null)
      setDisableClose(false)
      // Handle viem or gelato error
      if (
        e instanceof UserRejectedRequestError ||
        (e as Error).message.toLowerCase().includes('user rejected')
      ) {
        setError('The signature was rejected in your wallet.')
      } else if (e instanceof ProviderRpcError) {
        setError(e.shortMessage)
      } else if (e instanceof KytError) {
        setError(e.message)
      } else if (e instanceof Error && e.message) {
        setError(e.message.length > 100 ? e.message.substring(0, 100) + '...' : e.message)
      } else {
        setError('Something went wrong. Please try again or contact support.')
      }
    }
  }, [transaction, updateTransactionStatus, onTransactionComplete, txStatus, loggerAction])

  useEffect(() => {
    if (isAutoExecute && txStatus === 'auto-execute' && !hasAutoExecuted.current) {
      hasAutoExecuted.current = true
      handleSubmitTransaction()
    }
  }, [isAutoExecute, handleSubmitTransaction, txStatus])

  const handlePressComplete = useCallback(() => {
    onClose()
    if (onPressComplete) {
      onPressComplete()
    }
  }, [onClose, onPressComplete])

  return (
    <Modal
      title={
        !txStatus && !isAutoExecute
          ? reviewTitle
          : txStatus
          ? txStatusContext?.title
            ? txStatusContext.title
            : getDefaultTitle(txStatus)
          : null
      }
      isLarge
      subtitle={
        !txStatus && !isAutoExecute ? reviewSubtitle : txStatus ? getInstructionStr(txStatus) : null
      }
      {...modalControlProps}
      onClose={onClose}
      disableClose={disableClose || isPrivyModalOpen}
    >
      {txStatus ? (
        <WalletTransactionProgressScreen
          completeButtonProps={completeButtonProps}
          onPressComplete={handlePressComplete}
          transactionStatus={txStatus}
          transactionStatusContext={txStatusContext}
          disableClose={disableClose}
        />
      ) : (
        <>
          {isAutoExecute ? (
            error ? (
              <>
                <Section.Spinner status="retry" title="Transaction Failed" label={error} />
                {/* retry button */}
                <TransactionButtonRowInternal
                  isDisabled={isDisabled}
                  label={retryButtonLabel}
                  notice={notice ? <Notice {...notice} width="100%" /> : null}
                  onSubmitTransaction={handleSubmitTransaction}
                />
              </>
            ) : (
              <Section.Spinner />
            )
          ) : (
            <>
              {reviewContent}
              <TransactionButtonRowInternal
                isDisabled={isDisabled}
                label={reviewButtonLabel}
                notice={
                  error ? (
                    <Notice status="error" message={error} width="100%" />
                  ) : notice ? (
                    <Notice {...notice} width="100%" />
                  ) : null
                }
                onSubmitTransaction={handleSubmitTransaction}
                skipGeoblockCheckDONOTUSE={skipGeoblockCheckDONOTUSE}
              />
            </>
          )}
        </>
      )}
    </Modal>
  )
}
