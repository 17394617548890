import { getPagePath } from '../utils/pages'
import { PageId } from './pages'
import { BYBIT_WEB3_WALLET_CAMPAIGN_URL } from './urls'

export type Announcement = {
  id: string
  title: string
  description: string
  href: string
  target?: string
  imageUrl: string
}

export type SiteWideAnnouncement = {
  id: string
  message: string
  startTimestamp: number
  endTimestamp: number
  href?: string
  severity?: 'high' | 'medium' | 'low'
  isDismissible?: boolean
}

export const ANNOUNCEMENTS: Announcement[] = [
  {
    id: 'derive-drv',
    title: 'DRV Has Arrived',
    description: 'Claim, stake and delegate your DRV airdrop now.',
    href: getPagePath({ page: PageId.Airdrop }),
    imageUrl: '/images/tokens/drv.png',
  },
  {
    id: 'derive-etherfi',
    title: 'EtherFi Airdrop Bonus',
    description:
      'Deposit weETH and trade to earn a share of 2.5% of the airdrop, 4x Ether.Fi points boost and $3M ETHFI in rewards.',
    href: getPagePath({ page: PageId.DRV }),
    imageUrl: '/images/etherfi-bonus.png',
  },
  {
    id: 'derive-ethena',
    title: 'Ethena Airdrop Bonus',
    description:
      'Deposit USDe and trade to earn a share 2.5% of the airdrop and Ethena sats boosts.',
    href: getPagePath({ page: PageId.DRV }),
    imageUrl: '/images/ena-bonus.png',
  },
  {
    id: 'op-rewards-20k',
    title: '20k OP Rewards Pool',
    description:
      'In addition to DRV rewards, traders can now earn up to 20,000 OP per week based on trading fees paid.',
    href: getPagePath({ page: PageId.Leaderboard }),
    imageUrl: '/images/tokens/op.png',
  },
  {
    id: 'derive-derby',
    title: '$DRV Token Launch Jan 15',
    description:
      '$DRV token launch is scheduled for Jan 15. Enjoy 5x trading rewards, 20k OP per week, 50% off perp fees and airdrop bonuses.',
    href: getPagePath({ page: PageId.DRV }),
    imageUrl: '/images/tokens/drv.png',
  },
  {
    id: 'derive-bybit',
    title: 'Earn Rewards via Bybit',
    description: 'Earn a share of 27,777 OP & 250,000 DRV via the Bybit Web3 Wallet Campaign.',
    href: BYBIT_WEB3_WALLET_CAMPAIGN_URL,
    imageUrl: '/images/bybit.png',
  },
]

export const SITE_WIDE_ANNOUNCEMENTS: SiteWideAnnouncement[] = [
  {
    id: 'derive-derby',
    message:
      '$DRV token launch is scheduled for Jan 15. Enjoy 5x trading rewards, 20k OP per week, 50% off perp fees and airdrop bonuses.',
    startTimestamp: new Date('2024-12-01').getTime(),
    endTimestamp: new Date('2025-01-01').getTime(),
    href: getPagePath({ page: PageId.DRV }),
    isDismissible: true,
  },
]
