import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import Heading from '@lyra/core/components/Heading'
import Modal from '@lyra/core/components/Modal'
import Section from '@lyra/core/components/Section'
import { PageId } from '@lyra/web/constants/pages'
import { getPagePath } from '@lyra/web/utils/pages'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { YStack } from 'tamagui'

type Props = {
  onClose: () => void
}

export default function AirdropAnnouncementModal({ onClose }: Props) {
  const { push } = useRouter()
  return (
    <Modal onClose={() => onClose()} style={{ rowGap: 0 }}>
      <YStack gap="$6" paddingBottom="$2">
        <YStack justifyContent="center">
          <YStack marginHorizontal="auto">
            <Image
              width={260}
              height={260}
              src="/images/logo-animating.gif"
              alt="DRV is Live"
              style={{ pointerEvents: 'none' }}
            />
          </YStack>
          <Section.YStack justifyContent="center">
            <Heading size="h3" color="cta">
              $DRV Has Arrived
            </Heading>
            <BodyText size="lg">Claim, stake and delegate your DRV now</BodyText>
          </Section.YStack>
        </YStack>
        <Section.YStack>
          <Button
            onPress={() => {
              push(getPagePath({ page: PageId.Airdrop }))
              onClose()
            }}
            isSolid
            size="lg"
            color="cta"
            width="100%"
            label="Claim Airdrop"
          />
        </Section.YStack>
      </YStack>
    </Modal>
  )
}
