import { Address, Hash } from 'viem'

import { AirdropBonusId } from './points'

export type PartialUser = {
  // Smart contract wallet address
  address: Address
  // Owner wallet address
  ownerAddress: Address
  createdAt: number
  // Privy did
  did?: string
  // DO NOT USE
  // Privy dev did
  // Gets mapped to did when NEXT_PUBLIC_PRIVY_ENV is not set to "production"
  devDid?: string
  // User has acknowledged terms of use and privacy policy
  acknowledgedTerms?: boolean
  // User has acknowledged airdrop terms and conditions
  acknowledgedAirdropTerms?: boolean
  username?: string
  // notifications
  lastSeenNotificationsTimestamp?: number

  // Sometimes undefined if user has migrated -- use fetchAndMigrateUser for type safety
  skSignature?: Hash
  skTimestamp?: string
  skAddress?: Address

  // referrals
  referralCode: string
  referredByAddress?: Address

  // Map of airdrop bonus to complete timestamp
  airdropBonuses?: Partial<Record<AirdropBonusId, number>>

  // DEPRECATED auth fields - required for correct typing during migration
  authDEPRECATED?: {
    key?: Address
    address: Address
    signature: Hash
    timestamp: string
  }
}

export type UserAuthKeyFields = {
  // Account-level session key fields
  skSignature: Hash
  skTimestamp: string
  // Session key public key
  skAddress: Address
}

export type User = Omit<PartialUser, 'authDEPRECATED'> & UserAuthKeyFields

export const USERNAME_REGEX = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{3,13}$/

export const BANNED_USERNAMES = ['you']
