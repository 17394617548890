'use client'

import { PublicStatisticsResultSchema } from '@lyra/core/api/types/public.statistics'
import { useTheme, XStack } from '@lyra/core/components'
import BarChart, { BarChartDataKey } from '@lyra/core/components/BarChart/index'
import BodyText from '@lyra/core/components/BodyText'
import ChartTooltip from '@lyra/core/components/ChartTooltip'
import Checkbox from '@lyra/core/components/Checkbox'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import DropdownButton from '@lyra/core/components/DropdownButton'
import LineChart from '@lyra/core/components/LineChart'
import Section from '@lyra/core/components/Section'
import SectionGridLayout from '@lyra/core/components/SectionGridLayout'
import formatDate from '@lyra/core/utils/formatDate'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatTruncatedNumber from '@lyra/core/utils/formatTruncatedNumber'
import formatTruncatedUSD from '@lyra/core/utils/formatTruncatedUSD'
import formatUSD from '@lyra/core/utils/formatUSD'
import { CollateralId } from '@lyra/web/constants/tokens'
import useTvl from '@lyra/web/hooks/useTvl'
import {
  filterTradingSnapshots,
  groupDailySnapshots,
  groupDailyUserSnapshots,
} from '@lyra/web/utils/stats'
import { formatTokenSymbol } from '@lyra/web/utils/tokens'
import { parseAsJson, useQueryState } from 'next-usequerystate'
import { useCallback, useMemo } from 'react'

import {
  DailySnapshot,
  DailyUserSnapshot,
  STATS_START_DATE,
  StatsInstrumentFilter,
  StatsInstrumentType,
  StatsIntervalFilter,
  StatsMarketFilter,
  StatsTimeFilter,
} from '../../constants/stats'
import { coerce } from '../../utils/types'

type Props = {
  snapshots: DailySnapshot[]
  userSnapshots: DailyUserSnapshot[]
  statistics: PublicStatisticsResultSchema
}

const formatMarketLabel = (market: StatsMarketFilter) => {
  switch (market) {
    case StatsMarketFilter.ALL:
      return 'ALL MARKETS'
    default:
      return market.toUpperCase()
  }
}

const formatInstrumentLabel = (instrument: StatsInstrumentFilter) => {
  switch (instrument) {
    case StatsInstrumentFilter.ALL:
      return 'ALL INSTRUMENTS'
    case StatsInstrumentFilter.OPTION:
      return 'OPTIONS'
    case StatsInstrumentFilter.PERP:
      return 'PERPS'
    case StatsInstrumentFilter.SPOT:
      return 'SPOT'
  }
}

const formatTimeLabel = (timeframe: StatsTimeFilter) => {
  switch (timeframe) {
    case StatsTimeFilter.ALL:
      return 'ALL TIME'
    case StatsTimeFilter.THIRTY:
      return 'LAST 30 DAYS'
    case StatsTimeFilter.SIXTY:
      return 'LAST 60 DAYS'
    case StatsTimeFilter.ONE_EIGHTY:
      return 'LAST 180 DAYS'
  }
}

const formatIntervalLabel = (interval: StatsIntervalFilter) => {
  switch (interval) {
    case StatsIntervalFilter.DAILY:
      return 'Daily'
    case StatsIntervalFilter.WEEKLY:
      return 'Weekly'
    case StatsIntervalFilter.MONTHLY:
      return 'Monthly'
  }
}

const getIntervalNewConnetedWalletsKey = (interval: StatsIntervalFilter) => {
  switch (interval) {
    case StatsIntervalFilter.DAILY:
      return 'day'
    case StatsIntervalFilter.WEEKLY:
      return 'week'
    case StatsIntervalFilter.MONTHLY:
      return 'month'
  }
}

const getStartTimestamp = (interval: StatsTimeFilter): number => {
  const now = new Date()
  now.setUTCHours(0, 0, 0, 0) // midnight UTC
  switch (interval) {
    case StatsTimeFilter.ALL:
      return 0
    case StatsTimeFilter.THIRTY:
      now.setUTCDate(now.getUTCDate() - 30)
      return now.getTime()
    case StatsTimeFilter.SIXTY:
      now.setUTCDate(now.getUTCDate() - 60)
      return now.getTime()
    case StatsTimeFilter.ONE_EIGHTY:
      now.setUTCDate(now.getUTCDate() - 180)
      return now.getTime()
  }
}

const formatStatsInstrument = (instrument: StatsInstrumentType): string => {
  switch (instrument) {
    case StatsInstrumentType.OPTION:
      return 'Options'
    case StatsInstrumentType.PERP:
      return 'Perps'
    case StatsInstrumentType.SPOT:
      return 'Spot'
  }
}

const getInstrumentColor = (instrument: StatsInstrumentType): string => {
  switch (instrument) {
    case StatsInstrumentType.OPTION:
      return 'red_9'
    case StatsInstrumentType.PERP:
      return '#E1DDAD'
    case StatsInstrumentType.SPOT:
      return '#E8BBE2'
  }
}

const getCollateralColor = (collateralId: CollateralId): string => {
  switch (collateralId) {
    case CollateralId.USDC:
      return 'red_9'
    case CollateralId.ETH:
      return 'blue_9'
    case CollateralId.BTC:
      return 'amber_9'
    case CollateralId.SUSDE:
      return 'green_9'
    case CollateralId.USDT:
      return '#E8BBE2'
    case CollateralId.RSETH:
      return 'green_9'
    case CollateralId.RSWETH:
      return 'blue_11'
    case CollateralId.WSTETH:
      return '#90FDFC'
    case CollateralId.LBTC:
      return 'green_5'
    case CollateralId.SDAI:
      return '#E1DDAD'
    case CollateralId.WEETH:
      return '#4F498E'
    case CollateralId.DAI:
      return 'amber_6'
    case CollateralId.USDE:
      return 'green_11'
    case CollateralId.EBTC:
      return 'amber_12'
    case CollateralId.CBBTC:
      return 'amber_11'
    case CollateralId.OP:
      return 'red_10' // TODO: UPDATE COLOR @vikrvm
    case CollateralId.DEUSD:
      return 'green_10' // TODO: UPDATE COLOR @vikrvm
    case CollateralId.SOLVBTC:
      return 'amber_10' // TODO: UPDATE COLOR @vikrvm
    case CollateralId.SOLVBTCBBN:
      return 'blue_10' // TODO: UPDATE COLOR @vikrvm
    case CollateralId.DRV:
      return 'red_10'
    case CollateralId.STDRV:
      return 'red_11'
  }
}

const formatChartDate = (v: number, intervalFilter: StatsIntervalFilter) => {
  return intervalFilter === StatsIntervalFilter.MONTHLY
    ? new Date(v).toLocaleString('en-US', { timeZone: 'UTC', month: 'short' })
    : formatDate(v, { skipYear: true })
}

const CHART_HEIGHT = 280

export default function StatsPageHelper({ snapshots, userSnapshots, statistics }: Props) {
  const theme = useTheme()

  const { data: tvlData } = useTvl()

  const [filters, _setFilters] = useQueryState<{
    market?: StatsMarketFilter
    instrument?: StatsInstrumentFilter
    time?: StatsTimeFilter
    interval?: StatsIntervalFilter
    cumulative?: boolean
  }>('stats_filters', parseAsJson())

  const marketFilter = coerce(StatsMarketFilter, filters?.market, StatsMarketFilter.ALL)
  const setMarketFilter = useCallback(
    (market: StatsMarketFilter) => _setFilters({ ...filters, market }),
    [filters, _setFilters]
  )

  const instrumentFilter = coerce(
    StatsInstrumentFilter,
    filters?.instrument,
    StatsInstrumentFilter.ALL
  )
  const setInstrumentFilter = useCallback(
    (instrument: StatsInstrumentFilter) => _setFilters({ ...filters, instrument }),
    [filters, _setFilters]
  )

  const timeFilter = coerce(StatsTimeFilter, filters?.time, StatsTimeFilter.ALL)
  const setTimeFilter = useCallback(
    (time: StatsTimeFilter) => _setFilters({ ...filters, time }),
    [filters, _setFilters]
  )

  const intervalFilter = coerce(StatsIntervalFilter, filters?.interval, StatsIntervalFilter.WEEKLY)
  const setIntervalFilter = useCallback(
    (interval: StatsIntervalFilter) => _setFilters({ ...filters, interval }),
    [filters, _setFilters]
  )

  const isCumulative = !!filters?.cumulative
  const setIsCumulative = useCallback(
    (cumulative: boolean) => _setFilters({ ...filters, cumulative }),
    [filters, _setFilters]
  )

  const filteredSnapshots = useMemo(() => {
    const startTimestamp = getStartTimestamp(timeFilter)

    // filter by start timestamp, group by interval
    const filteredSnapshots = groupDailySnapshots(
      snapshots.filter(
        (s) => s.timestamp >= startTimestamp && s.timestamp >= STATS_START_DATE.getTime()
      ),
      intervalFilter
    )

    return filteredSnapshots
  }, [snapshots, timeFilter, intervalFilter])

  const { tradesSnapshots, volumeSnapshots, feesSnapshots } = useMemo(() => {
    return filterTradingSnapshots(filteredSnapshots, marketFilter, instrumentFilter, isCumulative)
  }, [filteredSnapshots, marketFilter, instrumentFilter, isCumulative])

  const tradeChartKeys: BarChartDataKey[] = useMemo(
    () =>
      Object.values(StatsInstrumentType).map((type) => {
        const color = getInstrumentColor(type)
        return {
          key: type,
          color: color in theme ? theme[color]?.get() : color,
          visible: true,
          stackId: 'a',
        }
      }),
    [theme]
  )

  const tvlSnapshots = useMemo(() => {
    return filteredSnapshots.map((s) => ({
      timestamp: s.timestamp,
      ...Object.entries(s.tvl).reduce(
        (dict, [key, val]) => ({
          ...dict,
          [key]: val.tvl,
        }),
        {} as Record<CollateralId, number>
      ),
    }))
  }, [filteredSnapshots])

  const tvlChartKeys: BarChartDataKey[] = useMemo(
    () =>
      Object.values(CollateralId).map((collateralId) => {
        const color = getCollateralColor(collateralId)
        return {
          key: collateralId,
          color: color in theme ? theme[color]?.get() : color,
          visible: true,
          stackId: 'a',
        }
      }),
    [theme]
  )

  const filteredUserSnapshots = useMemo(() => {
    const startTimestamp = getStartTimestamp(timeFilter)

    // filter by start timestamp, group by interval
    const filteredUserSnapshots = groupDailyUserSnapshots(
      userSnapshots.filter(
        (s) => s.timestamp >= startTimestamp && s.timestamp >= STATS_START_DATE.getTime()
      ),
      intervalFilter
    )

    return filteredUserSnapshots
  }, [userSnapshots, timeFilter, intervalFilter])

  const totalUsers = userSnapshots[userSnapshots.length - 1]?.cumulativeConnectedWallets ?? 0

  return (
    <>
      <Section isLarge noTopBorder>
        <Section.PageHeader title="Stats" />
        <Section.Grid minColWidth={100} maxColWidth={120}>
          <DataWithLabel label="Total Users" isEditorial value={formatNumber(totalUsers)} />
          <DataWithLabel
            label="TVL"
            isEditorial
            value={tvlData ? formatTruncatedUSD(tvlData.tvl, { dps: 2 }) : '...'}
          />
          <DataWithLabel
            isEditorial
            label={'Total Volume'}
            value={formatTruncatedUSD(+statistics.total_notional_volume, { dps: 2 })}
          />
          <DataWithLabel
            isEditorial
            label={'Total Fees'}
            value={formatTruncatedUSD(+statistics.total_fees, { dps: 2 })}
          />
          <DataWithLabel
            isEditorial
            label={'Total Trades'}
            value={formatNumber(+statistics.total_trades)}
          />
        </Section.Grid>
      </Section>
      <Section>
        <Section.XStack isCompact flexWrap="wrap" alignItems="center">
          <DropdownButton label={formatMarketLabel(marketFilter)}>
            {Object.values(StatsMarketFilter).map((filter) => (
              <DropdownButton.ListItem
                key={filter}
                label={formatMarketLabel(filter)}
                onPress={() => setMarketFilter(filter)}
                isSelected={filter === marketFilter}
              />
            ))}
          </DropdownButton>
          <DropdownButton label={formatInstrumentLabel(instrumentFilter)}>
            {Object.values(StatsInstrumentFilter).map((filter) => (
              <DropdownButton.ListItem
                key={filter}
                label={formatInstrumentLabel(filter)}
                onPress={() => setInstrumentFilter(filter)}
                isSelected={filter === instrumentFilter}
              />
            ))}
          </DropdownButton>
          <DropdownButton label={formatTimeLabel(timeFilter)}>
            {Object.values(StatsTimeFilter).map((filter) => (
              <DropdownButton.ListItem
                key={filter}
                label={formatTimeLabel(filter)}
                onPress={() => setTimeFilter(filter)}
                isSelected={filter === timeFilter}
              />
            ))}
          </DropdownButton>
          <DropdownButton label={formatIntervalLabel(intervalFilter)}>
            {Object.values(StatsIntervalFilter).map((filter) => (
              <DropdownButton.ListItem
                key={filter}
                label={formatIntervalLabel(filter)}
                onPress={() => setIntervalFilter(filter)}
                isSelected={filter === intervalFilter}
              />
            ))}
          </DropdownButton>
          <XStack gap="$1" onPress={() => setIsCumulative(!isCumulative)} cursor="pointer">
            <Checkbox isChecked={isCumulative} onPress={() => setIsCumulative(!isCumulative)} />
            <BodyText>Cumulative</BodyText>
          </XStack>
        </Section.XStack>
      </Section>
      <SectionGridLayout
        desktopGridTemplateColumns="repeat(auto-fill, minmax(550px, 1fr))"
        width="100%"
      >
        <Section noTopBorder isLarge>
          <Section.Header title="TVL" />
          <Section.Chart height={CHART_HEIGHT}>
            <BarChart
              width="100%"
              height="100%"
              data={tvlSnapshots}
              dataKeys={tvlChartKeys}
              yAxisProps={{ tickFormatter: (v) => formatTruncatedUSD(v), width: 45 }}
              xAxisProps={{
                dataKey: 'timestamp',
                tickFormatter: (v) => formatChartDate(v, intervalFilter),
              }}
              tooltipProps={{
                content: ({ active, payload, label }) => {
                  if (active && payload && payload.length && typeof label === 'number') {
                    const total = payload.reduce((acc, item) => acc + Number(item.value), 0)
                    const legend = payload
                      .sort((a, b) => +b.value! - +a.value!)
                      .filter(({ value }) => +value! > 0)
                      .map(({ dataKey, value, color }) => ({
                        dataKey: formatTokenSymbol(dataKey as CollateralId),
                        value: formatUSD(+value!, { dps: 0 }),
                        color: color!,
                      }))
                    return (
                      <ChartTooltip
                        totalLabel={formatUSD(total, { dps: 0 })}
                        xLabel={formatChartDate(label, intervalFilter)}
                        legend={legend}
                      />
                    )
                  }
                },
              }}
            />
          </Section.Chart>
        </Section>
        <Section noTopBorder isLarge>
          <Section.Header title="Volume" />
          <Section.Chart height={CHART_HEIGHT}>
            <BarChart
              width="100%"
              height="100%"
              data={volumeSnapshots}
              dataKeys={tradeChartKeys}
              yAxisProps={{ tickFormatter: (v) => formatTruncatedUSD(v), width: 45 }}
              xAxisProps={{
                dataKey: 'timestamp',
                tickFormatter: (v) => formatChartDate(v, intervalFilter),
              }}
              tooltipProps={{
                content: ({ active, payload, label }) => {
                  if (active && payload && payload.length && typeof label === 'number') {
                    const total = payload.reduce((acc, item) => acc + Number(item.value), 0)
                    const legend = payload
                      .sort((a, b) => +b.value! - +a.value!)
                      .filter(({ value }) => +value! > 0)
                      .map(({ dataKey, value, color }) => ({
                        dataKey: formatStatsInstrument(dataKey as StatsInstrumentType),
                        value: formatUSD(+value!, { dps: 0 }),
                        color: color!,
                      }))
                    return (
                      <ChartTooltip
                        totalLabel={formatUSD(total, { dps: 0 })}
                        xLabel={formatChartDate(label, intervalFilter)}
                        legend={legend}
                      />
                    )
                  }
                },
              }}
            />
          </Section.Chart>
        </Section>
        <Section noTopBorder isLarge>
          <Section.Header title="Trading Fees" />
          <Section.Chart height={CHART_HEIGHT}>
            <BarChart
              width="100%"
              height="100%"
              data={feesSnapshots}
              dataKeys={tradeChartKeys}
              yAxisProps={{ tickFormatter: (v) => formatTruncatedUSD(v), width: 45 }}
              xAxisProps={{
                dataKey: 'timestamp',
                tickFormatter: (v) => formatChartDate(v, intervalFilter),
              }}
              tooltipProps={{
                content: ({ active, payload, label }) => {
                  if (active && payload && payload.length && typeof label === 'number') {
                    const total = payload.reduce((acc, item) => acc + Number(item.value), 0)
                    const legend = payload
                      .sort((a, b) => +b.value! - +a.value!)
                      .filter(({ value }) => +value! > 0)
                      .map(({ dataKey, value, color }) => ({
                        dataKey: formatStatsInstrument(dataKey as StatsInstrumentType),
                        value: formatUSD(+value!, { dps: 0 }),
                        color: color!,
                      }))
                    return (
                      <ChartTooltip
                        totalLabel={formatUSD(total, { dps: 0 })}
                        xLabel={formatChartDate(label, intervalFilter)}
                        legend={legend}
                      />
                    )
                  }
                },
              }}
            />
          </Section.Chart>
        </Section>
        <Section noTopBorder isLarge>
          <Section.Header title="Trades" />
          <Section.Chart height={CHART_HEIGHT}>
            <BarChart
              height="100%"
              width="100%"
              data={tradesSnapshots}
              dataKeys={tradeChartKeys}
              yAxisProps={{ tickFormatter: (v) => formatTruncatedNumber(v), width: 45 }}
              xAxisProps={{
                dataKey: 'timestamp',
                tickFormatter: (v) => formatChartDate(v, intervalFilter),
              }}
              tooltipProps={{
                content: ({ active, payload, label }) => {
                  if (active && payload && payload.length && typeof label === 'number') {
                    const total = payload.reduce((acc, item) => acc + Number(item.value), 0)
                    const legend = payload
                      .sort((a, b) => +b.value! - +a.value!)
                      .filter(({ value }) => +value! > 0)
                      .map(({ dataKey, value, color }) => ({
                        dataKey: formatStatsInstrument(dataKey as StatsInstrumentType),
                        value: formatNumber(+value!),
                        color: color!,
                      }))
                    return (
                      <ChartTooltip
                        totalLabel={formatNumber(total)}
                        xLabel={formatChartDate(label, intervalFilter)}
                        legend={legend}
                      />
                    )
                  }
                },
              }}
            />
          </Section.Chart>
        </Section>
        <Section noTopBorder isLarge>
          <Section.Header
            title="Monthly Active Traders"
            subtitle="Monthly Active Trader: Wallet address that has a filled trade in the last 28 days"
          />
          <Section.Chart height={CHART_HEIGHT}>
            <BarChart
              height="100%"
              width="100%"
              data={filteredUserSnapshots}
              dataKeys={[
                {
                  key: 'trading.traders',
                  color: 'red',
                  visible: true,
                },
              ]}
              yAxisProps={{ tickFormatter: (v) => formatTruncatedNumber(v), width: 45 }}
              xAxisProps={{
                dataKey: 'timestamp',
                tickFormatter: (v) => formatChartDate(v, intervalFilter),
              }}
              tooltipProps={{
                content: ({ active, payload, label }) => {
                  if (active && payload && payload.length && typeof label === 'number') {
                    const total = payload.reduce((acc, item) => acc + Number(item.value), 0)
                    return (
                      <ChartTooltip
                        totalLabel=""
                        xLabel={formatChartDate(label, intervalFilter)}
                        legend={[
                          {
                            dataKey: 'Monthly Active Traders',
                            value: formatNumber(total),
                            color: 'red',
                          },
                        ]}
                      />
                    )
                  }
                },
              }}
            />
          </Section.Chart>
        </Section>
        <Section noTopBorder isLarge>
          <Section.Header
            title="Cumulative New Users"
            subtitle="New User: New wallet address that is active on Derive"
          />
          <Section.Chart height={CHART_HEIGHT}>
            <LineChart
              height="100%"
              width="100%"
              data={filteredUserSnapshots}
              chartMargin={{
                right: 18,
              }}
              xAxisDataKey="timestamp"
              dataKeys={[
                {
                  key: 'cumulativeConnectedWallets',
                  color: 'red',
                },
              ]}
              barDataKeys={[
                {
                  key: `newConnectedWallets.${getIntervalNewConnetedWalletsKey(intervalFilter)}`,
                  color: '#E1DDAD',
                  visible: true,
                },
              ]}
              yAxisProps={{ tickFormatter: (v) => formatTruncatedNumber(v), width: 45 }}
              xAxisProps={{
                tickFormatter: (v) => formatChartDate(v, intervalFilter),
              }}
              tooltipProps={{
                content: ({ active, payload, label }) => {
                  if (active && payload && payload.length && typeof label === 'number') {
                    const legend = payload.map(({ dataKey, value, color }) => ({
                      dataKey:
                        dataKey === 'cumulativeConnectedWallets'
                          ? 'Cumulative New Users'
                          : `${formatIntervalLabel(intervalFilter)} New Users`,
                      value: formatNumber(+value!),
                      color: color!,
                    }))
                    return (
                      <ChartTooltip
                        totalLabel=""
                        xLabel={formatChartDate(label, intervalFilter)}
                        legend={legend}
                      />
                    )
                  }
                },
              }}
            />
          </Section.Chart>
        </Section>
      </SectionGridLayout>
    </>
  )
}
