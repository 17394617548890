import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import DataLabelRow from '@lyra/core/components/DataLabelRow'
import DropdownButton from '@lyra/core/components/DropdownButton'
import BigNumberInput from '@lyra/core/components/Input/BigNumberInput'
import LabelText from '@lyra/core/components/LabelText'
import Section from '@lyra/core/components/Section'
import SpanText from '@lyra/core/components/SpanText'
import Tag from '@lyra/core/components/Tag'
import TooltipText from '@lyra/core/components/TooltipText'
import NetworkIcon from '@lyra/web/components/common/NetworkIcon'
import TokenIcon from '@lyra/web/components/common/TokenIcon'
import { DepositNetwork } from '@lyra/web/constants/chains'
import { collateralConfig, DepositTokenId } from '@lyra/web/constants/tokens'
import useCollateralApys from '@lyra/web/hooks/useCollateralApys'
import { DepositQuote } from '@lyra/web/hooks/useDepositQuote'
import { formatDepositNetworkName } from '@lyra/web/utils/chains'
import {
  formatCollateralApy,
  formatDepositTokenBalance,
  formatDepositTokenSymbol,
  getCollateralForDepositToken,
  getTokenDecimals,
} from '@lyra/web/utils/tokens'
import { useCallback, useMemo } from 'react'
import { XStack } from 'tamagui'

type Props = {
  balances: Record<DepositTokenId, bigint>
  // Amount input
  depositAmount: bigint
  depositQuote?: DepositQuote
  error?: Error
  isDepositQuoteLoading?: boolean
  isInsufficientBalance?: boolean
  maxDepositAmount: bigint
  networks: DepositNetwork[]
  // Network input
  selectedNetwork: DepositNetwork
  // Token input
  selectedToken: DepositTokenId
  supportedTokens: DepositTokenId[]
  onChangeDepositAmount: (amount: bigint) => void
  onChangeNetwork: (network: DepositNetwork) => void
  onChangeToken: (token: DepositTokenId) => void
}

const FEATURED_NETWORKS: DepositNetwork[] = [
  DepositNetwork.Arbitrum,
  DepositNetwork.Base,
  DepositNetwork.Optimism,
]

const FEATURED_TOKENS: DepositTokenId[] = [
  DepositTokenId.DRV,
  DepositTokenId.USDC,
  DepositTokenId.USDCe,
  DepositTokenId.USDT,
  DepositTokenId.ETH,
  DepositTokenId.WSTETH,
  DepositTokenId.WEETH,
  DepositTokenId.WBTC,
  DepositTokenId.LBTC,
  DepositTokenId.CBBTC,
  DepositTokenId.SDAI,
  DepositTokenId.SUSDE,
  DepositTokenId.OP,
  DepositTokenId.SOLVBTC,
  DepositTokenId.SOLVBTCBBN,
]

export default function DepositModalRows({
  networks,
  selectedNetwork,
  selectedToken,
  depositAmount,
  maxDepositAmount,
  depositQuote,
  isDepositQuoteLoading,
  error,
  balances,
  isInsufficientBalance,
  supportedTokens,
  onChangeDepositAmount,
  onChangeNetwork,
  onChangeToken,
}: Props) {
  const featuredTokens = useMemo(
    () => FEATURED_TOKENS.filter((token) => supportedTokens.includes(token)),
    [supportedTokens]
  )

  const formatInputValue = useCallback(
    (val: bigint) => formatDepositTokenBalance(val, selectedToken),
    [selectedToken]
  )

  const selectedCollateralId = getCollateralForDepositToken(selectedToken)
  const { data: apys, isLoading } = useCollateralApys()
  const selectedApy = apys ? apys[selectedCollateralId] ?? 0 : 0
  const selectedYieldConfig = collateralConfig[selectedCollateralId].yield

  return (
    <>
      <Section.YStack>
        <LabelText>Network</LabelText>
        <DropdownButton
          strategy="fixed"
          leftIcon={<NetworkIcon network={selectedNetwork} />}
          size="lg"
          label={selectedNetwork}
          textAlign="left"
        >
          {networks.map((network) => (
            <DropdownButton.ListItem
              icon={<NetworkIcon network={network} />}
              label={
                <SpanText textTransform="none">
                  {formatDepositNetworkName(network).toUpperCase()}
                </SpanText>
              }
              key={network}
              onPress={() => onChangeNetwork(network)}
              isSelected={network === selectedNetwork}
              size="lg"
            />
          ))}
        </DropdownButton>
        <XStack gap="$1" flexWrap="wrap">
          {FEATURED_NETWORKS.map((network) => (
            <Tag
              key={network}
              icon={<NetworkIcon network={network} />}
              label={network}
              onPress={() => onChangeNetwork(network)}
            />
          ))}
        </XStack>
      </Section.YStack>
      <Section.YStack>
        <LabelText>Token</LabelText>
        <DropdownButton
          strategy="fixed"
          leftIcon={<TokenIcon size={18} symbol={selectedToken} />}
          size="lg"
          label={
            <SpanText textTransform="none">{formatDepositTokenSymbol(selectedToken)}</SpanText>
          }
          textAlign="left"
          textTransform="none"
        >
          {supportedTokens.map((token) => {
            const collateralId = getCollateralForDepositToken(token)
            const yieldConfig = collateralConfig[collateralId].yield
            const apy = apys ? apys[collateralId] ?? 0 : 0
            return (
              <DropdownButton.ListItem
                key={token}
                size="lg"
                icon={<TokenIcon size={18} symbol={token} />}
                label={<SpanText textTransform="none">{formatDepositTokenSymbol(token)}</SpanText>}
                sublabel={formatDepositTokenBalance(balances[token], token)}
                rightLabel={
                  yieldConfig ? (
                    <TooltipText
                      color="green"
                      label={formatCollateralApy(collateralId, apy)}
                      tooltipContent={yieldConfig.copy}
                    />
                  ) : null
                }
                onPress={() => onChangeToken(token)}
                isSelected={selectedToken === token}
              />
            )
          })}
        </DropdownButton>
        <XStack flexWrap="wrap" gap="$1">
          {featuredTokens.map((depositToken) => (
            <Tag
              key={depositToken}
              label={formatDepositTokenSymbol(depositToken)}
              icon={<TokenIcon symbol={depositToken} />}
              onPress={() => onChangeToken(depositToken)}
              textTransform="none"
            />
          ))}
        </XStack>
      </Section.YStack>
      <Section.YStack>
        <BigNumberInput
          label="Amount"
          rightLabel={`Available: ${formatDepositTokenBalance(
            balances[selectedToken],
            selectedToken
          )}`}
          decimals={getTokenDecimals(selectedToken)}
          flexGrow={1}
          flexShrink={1}
          formatValue={formatInputValue}
          size="lg"
          status={isInsufficientBalance && depositAmount ? 'error' : 'default'}
          value={depositAmount}
          onChangeValue={onChangeDepositAmount}
          rightContent={
            maxDepositAmount > BigInt(0) ? (
              <Button
                size="sm"
                label="Max"
                onPress={() => onChangeDepositAmount(maxDepositAmount)}
                isSelected={depositAmount !== BigInt(0) && depositAmount === maxDepositAmount}
              />
            ) : null
          }
        />
      </Section.YStack>
      <Section.YStack>
        <DataLabelRow
          label="Network Fee"
          value={
            isDepositQuoteLoading || !depositQuote || error ? (
              <BodyText>...</BodyText>
            ) : (
              <BodyText>
                {formatDepositTokenBalance(depositQuote.fee, depositQuote.feeToken)}
              </BodyText>
            )
          }
        />
        {selectedYieldConfig ? (
          <DataLabelRow
            label="APY"
            value={
              <TooltipText
                color="green"
                label={isLoading ? '...' : formatCollateralApy(selectedCollateralId, selectedApy)}
                tooltipContent={selectedYieldConfig.copy}
              />
            }
          />
        ) : null}
      </Section.YStack>
    </>
  )
}
