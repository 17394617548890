import { X } from '@tamagui/lucide-icons'
import React, { useCallback, useEffect } from 'react'
import { Dialog } from 'tamagui'

import Button from '../Button'
import Section, { SectionProps } from '../Section'

const DESKTOP_MODAL_WIDTH = 420

export type ModalControlProps = {
  desktopWidth?: number | string
  disableClose?: boolean
} & (
  | {
      // RECOMMENDED!!
      // for modals that mount/re-mount when they are opened
      // best for reseting state when a modal closes
      onClose: () => void
      onChangeOpen?: undefined
      isOpen?: undefined
    }
  | {
      // for modals that mount before they are opened
      // best for persisting state when a modal closes
      isOpen: boolean
      onChangeOpen: (isOpen: boolean) => void
      onClose?: undefined
    }
)

export type ModalProps = {
  children: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
} & ModalControlProps &
  SectionProps

type DialogSectionProps = {
  children: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  onChangeOpen: (isOpen: boolean) => void
} & Pick<ModalControlProps, 'disableClose'> &
  SectionProps

const DialogSection = ({
  title,
  subtitle,
  children,
  disableClose,
  onChangeOpen,
  ...sectionProps
}: DialogSectionProps) => {
  const closeButton = !disableClose ? (
    <Button
      marginLeft="auto"
      icon={<X />}
      onPress={(e) => {
        e.stopPropagation()
        onChangeOpen(false)
      }}
    />
  ) : null

  return (
    <Section backgroundColor="$appBg" {...sectionProps}>
      {title || subtitle || !disableClose ? (
        <Section.Header zIndex={1} title={title} subtitle={subtitle} rightContent={closeButton} />
      ) : null}
      {children}
    </Section>
  )
}

export default function Modal({
  isOpen: isOpenInput,
  disableClose,
  desktopWidth,
  onChangeOpen,
  onClose,
  ...contentProps
}: ModalProps) {
  const handleChangeOpen = useCallback(
    (isOpen: boolean) => {
      if (disableClose) {
        return
      }
      if (onChangeOpen) {
        onChangeOpen(isOpen)
      } else if (!isOpen) {
        onClose()
      }
    },
    [disableClose, onChangeOpen, onClose]
  )

  // if onChangeOpen is defined, user is manually handling isOpen state
  // else user is relying on onClose to close modal
  const isOpen = onChangeOpen ? isOpenInput : true

  useEffect(() => {
    const handler = (e: MouseEvent) => e.stopPropagation()
    window.addEventListener('click', handler)
    return () => {
      window.removeEventListener('click', handler)
    }
  }, [])

  return (
    <Dialog open={isOpen} onOpenChange={handleChangeOpen} modal>
      <Dialog.Portal>
        <Dialog.Overlay
          forceMount
          key="overlay"
          animation="ease-regular"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          backgroundColor="$overlay"
          backdropFilter="blur(2px)"
        />
        <Dialog.Content
          $desktop={{
            width: desktopWidth ?? DESKTOP_MODAL_WIDTH,
            maxWidth: desktopWidth ?? DESKTOP_MODAL_WIDTH,
            paddingVertical: '$8',
            marginBottom: 'auto',
          }}
          $mobile={{
            width: '100%',
            maxWidth: '100%',
            marginTop: 'auto',
          }}
          key="content"
          animation="ease-regular"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          maxHeight="100%"
          style={{ overflowY: 'auto' }}
        >
          {/* Prevent warning about dialog titles */}
          <Dialog.Title display="none" />
          <DialogSection
            onChangeOpen={handleChangeOpen}
            disableClose={disableClose}
            {...contentProps}
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  )
}
