import { Book, ExternalLink, Landmark, TestTube } from '@lyra/core/components/Icon'
import Discord from '@lyra/core/components/Icon/DiscordIcon'
import filterNulls from '@lyra/core/utils/filterNulls'
import { PageId, Pathname } from '@lyra/web/constants/pages'
import { MarketId } from '@lyra/web/src/constants/markets'
import { getPagePath } from '@lyra/web/utils/pages'

import { isMainnet, isTestnet } from '../constants/env'
import { CollateralId } from '../constants/tokens'
import { DISCORD_URL, DOCS_URL, GOVERNANCE_PORTAL_URL, TESTNET_APP_URL } from '../constants/urls'
import { removeQueryAndHashFromPathname } from './pages'

export enum TabId {
  Home = 'Home',
  Trade = 'Trade',
  Earn = 'Earn',
  Borrow = 'Borrow',
  Activity = 'Activity',
  DRV = 'DRV',
  Stake = 'Stake',
}

export type TabDetails = {
  id: TabId
  name: string
  pages: [TabPage, ...TabPage[]]
  prefixes?: string[]
  isDesktop?: boolean
  isMobile?: boolean
}

export type TabPage = {
  name: string
  id: PageId
  path: Pathname
}

export type MoreTabPage = {
  path: string
  name: string
  isNewTab?: boolean
  icon?: React.NamedExoticComponent
}

type TabOptions = {
  defaultOptionsMarket?: MarketId
  defaultPerpsMarket?: MarketId
  defaultSpotCollateral?: CollateralId
}

export const getMoreTabPages = (isMobile: boolean): MoreTabPage[] => {
  const extraTabs = Object.values(getTabs()).filter(
    (tab) => (isMobile && !tab.isMobile) || (!isMobile && !tab.isDesktop)
  )

  return filterNulls([
    ...extraTabs.map((tab) => ({
      path: tab.pages[0].path,
      name: tab.name,
    })),
    {
      path: GOVERNANCE_PORTAL_URL,
      name: 'GOVERNANCE',
      isNewTab: true,
      icon: Landmark,
    },
    !isTestnet
      ? {
          path: TESTNET_APP_URL,
          name: 'TESTNET',
          icon: TestTube,
          isNewTab: true,
        }
      : null,
    {
      path: DOCS_URL,
      name: 'DOCUMENTATION',
      icon: Book,
    },
    {
      path: DISCORD_URL,
      name: 'COMMUNITY',
      icon: Discord as any,
    },
    {
      path: getPagePath({ page: PageId.TermsOfUse }),
      name: 'TERMS OF USE',
      isNewTab: true,
      icon: ExternalLink,
    },
    {
      path: getPagePath({ page: PageId.PrivacyPolicy }),
      name: 'PRIVACY POLICY',
      isNewTab: true,
      icon: ExternalLink,
    },
  ])
}

export const getTabs = (options?: TabOptions): Record<TabId, TabDetails> => {
  const { defaultPerpsMarket } = options ?? {}
  return {
    [TabId.Home]: {
      id: TabId.Home,
      name: 'HOME',
      isDesktop: true,
      isMobile: true,
      pages: [
        {
          path: getPagePath({ page: PageId.Home }),
          name: 'HOME',
          id: PageId.Home,
        },
        {
          path: getPagePath({ page: PageId.Balances }),
          name: 'BALANCES',
          id: PageId.Balances,
        },
        {
          path: getPagePath({ page: PageId.Positions }),
          name: 'POSITIONS',
          id: PageId.Positions,
        },
        {
          path: getPagePath({ page: PageId.History }),
          name: 'HISTORY',
          id: PageId.History,
        },
        {
          path: getPagePath({ page: PageId.Developers }),
          name: 'DEVELOPERS',
          id: PageId.Developers,
        },
      ],
    },
    [TabId.Trade]: {
      id: TabId.Trade,
      name: 'TRADE',
      isDesktop: true,
      isMobile: true,
      pages: [
        {
          path: getPagePath({ page: PageId.Perps, marketId: defaultPerpsMarket }),
          name: 'PERPS',
          id: PageId.Perps,
        },
      ],
      prefixes: ['/perps'],
    },
    [TabId.Earn]: {
      id: TabId.Earn,
      name: 'EARN',
      isDesktop: true,
      isMobile: true,
      pages: [
        {
          path: getPagePath({ page: PageId.Earn }),
          name: 'EARN',
          id: PageId.Earn,
        },
      ],
      prefixes: ['/vault'],
    },
    [TabId.Borrow]: {
      id: TabId.Borrow,
      name: 'BORROW',
      isDesktop: true,
      isMobile: false,
      pages: [
        {
          path: getPagePath({ page: PageId.Borrow }),
          name: 'BORROW',
          id: PageId.Borrow,
        },
      ],
      prefixes: ['/token'],
    },
    // hide leaderboard on testnet
    [TabId.Activity]: {
      id: TabId.Activity,
      name: 'ACTIVITY',
      isDesktop: isMainnet,
      prefixes: ['/activity', '/stats', '/user', '/leaderboard'],
      pages: [
        {
          path: getPagePath({ page: PageId.Leaderboard }),
          name: 'LEADERBOARD',
          id: PageId.Leaderboard,
        },
        {
          path: getPagePath({ page: PageId.Trades }),
          name: 'TRADES',
          id: PageId.Trades,
        },
        {
          name: 'STATS',
          id: PageId.Stats,
          path: getPagePath({ page: PageId.Stats }),
        },
        {
          name: 'AMBERDATA',
          id: PageId.Amberdata,
          path: getPagePath({ page: PageId.Amberdata }),
        },
      ],
    },
    [TabId.DRV]: {
      id: TabId.DRV,
      name: '$DRV',
      isDesktop: true,
      isMobile: true,
      prefixes: ['/referrals', '/rounds'],
      pages: [
        {
          name: 'DRV',
          id: PageId.DRV,
          path: getPagePath({ page: PageId.DRV }),
        },
      ],
    },
    [TabId.Stake]: {
      id: TabId.Stake,
      name: 'STAKE',
      isDesktop: true,
      pages: [
        {
          name: 'STAKE',
          id: PageId.Stake,
          path: getPagePath({ page: PageId.Stake }),
        },
      ],
    },
  }
}

export const getTabIdForPath = (pathname: string): TabId | null => {
  const cleanPathname = removeQueryAndHashFromPathname(pathname)

  const tab = Object.values(getTabs()).find((tab) => {
    return (
      tab.pages.some((page) => cleanPathname.startsWith(page.path)) ||
      tab.prefixes?.some((prefix) => cleanPathname.startsWith(prefix))
    )
  })

  return tab ? tab.id : null
}

export const getTabPageIdForPath = (pathname: string): PageId | string | null => {
  const cleanPathname = removeQueryAndHashFromPathname(pathname)

  const tabPages = Object.values(getTabs()).flatMap((tab) => tab.pages)

  const tabPage = tabPages.find((page) => {
    return cleanPathname.startsWith(page.path)
  })

  return tabPage ? tabPage.id : null
}
