import { XStack } from '@lyra/core/components'
import BodyText from '@lyra/core/components/BodyText'
import Dropdown from '@lyra/core/components/Dropdown'
import Icon, { ChevronDown } from '@lyra/core/components/Icon'
import VideoText from '@lyra/core/components/VideoText'
import useHover from '@lyra/core/hooks/useHover'
import usePress from '@lyra/core/hooks/usePress'
import { Pathname } from '@lyra/web/constants/pages'
import { useRouter } from 'next/navigation'
import React, { useEffect } from 'react'
import { StackProps, TextProps } from 'tamagui'

type TabParams = {
  path?: Pathname | string
  onPress?: () => void
  isSelected?: boolean
  isDisabled?: boolean
  name: string
  dropdown?: {
    name: string
    onPress?: () => void
    isSelected?: boolean
  }[]
}

type TabProps = TabParams & {
  isBodyText?: boolean
  textTransform?: TextProps['textTransform']
} & StackProps

type TabSize = 'sm' | 'md' | 'lg'

type Props = {
  pages: TabParams[]
  size?: TabSize
  tabProps?: StackProps
  isBodyText?: boolean
  textTransform?: TextProps['textTransform']
} & StackProps

const Tab = ({
  isSelected,
  name,
  path,
  onPress,
  isBodyText,
  textTransform,
  dropdown,
  isDisabled,
  ...stackProps
}: TabProps) => {
  const { prefetch, push } = useRouter()

  useEffect(() => {
    if (path) {
      prefetch(path)
    }
  }, [prefetch, path])

  const { isHovering, handleHoverIn, handleHoverOut } = useHover()
  const { isPressing, handlePressIn, handlePressOut } = usePress()

  const color = isDisabled
    ? 'secondary'
    : isPressing
    ? 'inverted'
    : isSelected || isHovering
    ? 'primary'
    : 'secondary'

  const content = (
    <XStack
      {...stackProps}
      justifyContent="center"
      alignItems="center"
      cursor={isDisabled ? 'default' : 'pointer'}
      borderBottomWidth={1}
      borderColor={isSelected ? '$invertedBg' : 'transparent'}
      backgroundColor={
        isDisabled ? undefined : isPressing ? '$invertedBg' : isHovering ? '$hoverBg' : undefined
      }
      onPress={onPress ? onPress : path ? () => push(path) : undefined}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      gap="$1"
    >
      {isBodyText ? (
        <BodyText color={color}>{name}</BodyText>
      ) : (
        <VideoText textTransform={textTransform} color={color}>
          {name}
        </VideoText>
      )}
      {dropdown ? <Icon icon={<ChevronDown />} size={12} color={color} /> : null}
    </XStack>
  )

  if (dropdown) {
    return (
      <Dropdown trigger={content}>
        {dropdown.map((item) => (
          <XStack
            hoverStyle={{ backgroundColor: '$pressBg' }}
            cursor="pointer"
            key={item.name}
            onPress={item.onPress}
            padding="$1.5"
          >
            <BodyText color={item.isSelected ? 'primary' : 'secondary'}>{item.name}</BodyText>
          </XStack>
        ))}
      </Dropdown>
    )
  }

  return content
}

const getPadding = (size: TabSize) => {
  switch (size) {
    case 'sm':
      return {
        paddingHorizontal: '$2',
        paddingVertical: '$1.5',
      }
    case 'md':
      return {
        paddingHorizontal: '$3',
        paddingVertical: '$2',
      }
    case 'lg':
      return {
        paddingHorizontal: '$3',
        paddingVertical: '$3',
      }
  }
}

export default function TabsHorizontal({
  pages,
  size = 'md',
  tabProps,
  isBodyText,
  textTransform,
  ...stackProps
}: Props) {
  return (
    <XStack {...stackProps}>
      {pages.map(({ path, onPress, isSelected, name, dropdown, isDisabled }) => {
        return (
          <Tab
            key={name}
            {...getPadding(size)}
            {...tabProps}
            path={path}
            onPress={onPress}
            isSelected={isSelected}
            isDisabled={isDisabled}
            name={name}
            isBodyText={isBodyText}
            textTransform={textTransform}
            dropdown={dropdown}
          />
        )
      })}
    </XStack>
  )
}
