'use client'

import { NAV_MARQUEE_HEIGHT, NAV_MOBILE_TABS_HEIGHT } from '@lyra/web/constants/layout'
import useNavHeight from '@lyra/web/hooks/useNavHeight'
import useSelectedTab from '@lyra/web/hooks/useSelectedTab'
import useSelectedTabPage from '@lyra/web/hooks/useSelectedTabPage'
import { TabPage } from '@lyra/web/utils/tabs'
import React, { useMemo } from 'react'
import { StackProps, XStack, YStack } from 'tamagui'

import TabsHorizontal from './TabsHorizontal'
import TabsVertical from './TabsVertical'

type Props = {
  pageWidth?: number
  pages: TabPage[]
  children: React.ReactNode
} & StackProps

const LEFT_NAV_WIDTH = 180

export default function LayoutWithNav({ pageWidth = 2400, pages, children, ...stackProps }: Props) {
  const tab = useSelectedTab()
  const selectedPage = useSelectedTabPage()
  const { desktopHeight } = useNavHeight()
  const tabPages = useMemo(() => {
    return tab
      ? tab.pages.map((page) => ({
          ...page,
          isSelected: selectedPage?.id === page.id,
        }))
      : []
  }, [tab, selectedPage?.id])

  return (
    <XStack width="100%" justifyContent="center" {...stackProps}>
      {/* desktop left nav */}
      <YStack
        width={LEFT_NAV_WIDTH}
        height={`calc(100vh-${desktopHeight + NAV_MARQUEE_HEIGHT}px)`}
        style={{
          position: 'fixed',
          left: 0,
          top: desktopHeight,
          bottom: NAV_MARQUEE_HEIGHT,
        }}
        borderRightWidth={1}
        borderColor="$hairline"
        $mobile={{ display: 'none' }}
      >
        <TabsVertical pages={tabPages} />
      </YStack>
      {/* desktop + mobile page contents */}
      <YStack
        $desktop={{
          marginLeft: LEFT_NAV_WIDTH,
          maxWidth: pageWidth,
        }}
        $mobile={{
          width: '100%',
        }}
        flexGrow={1}
        flexShrink={1}
        justifyContent="center"
        style={{ overflowX: 'hidden', position: 'relative' }}
      >
        <TabsHorizontal
          pages={pages}
          style={{ overflowX: 'auto' }}
          backgroundColor="$appBg"
          borderBottomColor="$hairline"
          borderBottomWidth={1}
          $desktop={{ display: 'none' }}
          height={NAV_MOBILE_TABS_HEIGHT}
          size="lg"
        />
        {children}
      </YStack>
    </XStack>
  )
}
