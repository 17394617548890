'use client'

import { datadogRum } from '@datadog/browser-rum'
import { YStack } from '@lyra/core/components'
import isServer from '@lyra/core/utils/isServer'
import spindl from '@spindl-xyz/attribution'
import { usePathname } from 'next/navigation'
import React, { useEffect } from 'react'

import AirdropAnnouncementModal from '../components/common/AirdropAnnouncementModal'
import { env, isMainnet } from '../constants/env'
import { NAV_MARQUEE_HEIGHT, NAV_MOBILE_TABS_HEIGHT } from '../constants/layout'
import { LOCAL_STORAGE_AIRDROP_KEY } from '../constants/localStorage'
import Nav from '../containers/common/Nav'
import useBoolLocalStorage from '../hooks/local_storage/useBoolLocalStorage'
import useAdmin from '../hooks/useAdmin'
import useAuth from '../hooks/useAuth'
import useNavHeight from '../hooks/useNavHeight'
import { beforeSendFilter } from '../utils/logger'
import { removeQueryAndHashFromPathname } from '../utils/pages'
import DeployPasswordPageHelper from './misc/DeployPasswordPageHelper'

if (
  isMainnet &&
  ['production', 'preview'].includes(process.env.NEXT_PUBLIC_VERCEL_ENV ?? '') &&
  process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
  process.env.NEXT_PUBLIC_DATADOG_APP_ID
) {
  const orderbookApiUrl = process.env.NEXT_PUBLIC_ORDERBOOK_API_URL
  try {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'client',
      env: process.env.NEXT_PUBLIC_VERCEL_ENV,
      version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ?? 'local',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: orderbookApiUrl ? [(url) => url.startsWith(orderbookApiUrl)] : undefined,
      beforeSend: beforeSendFilter,
    })
    console.debug('Datadog intialized')
  } catch (error) {
    console.debug('Something went wrong intializing Datadog')
  }
}

// Initialize Spindl
try {
  if (process.env.NEXT_PUBLIC_SPINDL_API_KEY && !isServer) {
    spindl.configure({ sdkKey: process.env.NEXT_PUBLIC_SPINDL_API_KEY })
    console.debug('Spindl initialized')
  }
} catch (error) {
  console.debug('Something went wrong initializing Spindl')
}

type Props = {
  children?: React.ReactNode
}

export default function AppLayoutHelper({ children }: Props) {
  const { isDeployLocked } = useAdmin()
  const { user } = useAuth()
  const pathname = usePathname()
  const cleanedPathname = removeQueryAndHashFromPathname(pathname)
  const { mobileHeight, desktopHeight } = useNavHeight()
  const [hasSeenAirdropModal, setHasSeenAirdropModal] =
    useBoolLocalStorage(LOCAL_STORAGE_AIRDROP_KEY)

  // only log pageView when cleanedPathname updates
  useEffect(() => {
    // Log page views to Spindl
    if (process.env.NEXT_PUBLIC_SPINDL_API_KEY) {
      try {
        spindl.configure({ sdkKey: process.env.NEXT_PUBLIC_SPINDL_API_KEY })
        spindl.pageView()
      } catch (err) {
        console.warn('Spindl pageView failed')
      }
    }
  }, [cleanedPathname])

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user.address,
        ownerAddress: user.ownerAddress,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.address])

  const showPagePadding = !isDeployLocked

  useEffect(() => {
    console.debug('env:', {
      vercel: process.env.NEXT_PUBLIC_VERCEL_ENV,
      app: env,
    })
  }, [])

  const showAirdropModal = !hasSeenAirdropModal && pathname !== '/airdrop'
  useEffect(() => {
    if (pathname === '/airdrop') {
      setHasSeenAirdropModal(true)
    }
  }, [pathname, setHasSeenAirdropModal])

  return (
    <YStack
      // Warning: #root enforces css for page heights, do not remove
      id="root"
      alignItems="center"
      $mobile={{
        paddingTop: showPagePadding ? mobileHeight : 0,
        paddingBottom: showPagePadding ? NAV_MOBILE_TABS_HEIGHT : 0,
      }}
      $desktop={{
        paddingTop: showPagePadding ? desktopHeight : 0,
        paddingBottom: showPagePadding ? NAV_MARQUEE_HEIGHT : 0,
      }}
    >
      {isDeployLocked ? (
        <DeployPasswordPageHelper />
      ) : (
        <>
          <Nav />
          {children}
          {showAirdropModal ? (
            <AirdropAnnouncementModal onClose={() => setHasSeenAirdropModal(true)} />
          ) : null}
        </>
      )}
    </YStack>
  )
}
