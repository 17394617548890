import { PageArgsMap, PageId, PagePathArgs, Pathname } from '@lyra/web/src/constants/pages'

import { DEFAULT_MARKET_ID } from '../constants/markets'
import { OrderQueryParam, QueryParam } from '../constants/query'
import { formatExpiryCode } from './instruments'

export const removeQueryAndHashFromPathname = (pathname: string) => {
  let cleanPathname = pathname.split('?')[0] // Split at the query parameter start and take the first part
  cleanPathname = cleanPathname.split('#')[0] // Split at the hash parameter start and take the first part
  return cleanPathname
}

const getEncodedOrderParam = (order: OrderQueryParam): string => {
  const encodedParam =
    typeof order === 'string'
      ? order
      : encodeURIComponent(JSON.stringify(order))
          .replace(/%22/g, '"') // Keep quotes unencoded
          .replace(/%7B/g, '{') // Keep curly braces unencoded
          .replace(/%7D/g, '}') // Keep curly braces unencoded
          .replace(/%3A/g, ':') // Keep colons unencoded
          .replace(/%2C/g, ',') // Keep commas unencoded
  return encodedParam
}

const getOptionsPathname = (args: PageArgsMap[PageId.Options] = {}): string => {
  const market = args.marketId ?? DEFAULT_MARKET_ID
  const params = new URLSearchParams()
  if (args.order) {
    const encodedParam = getEncodedOrderParam(args.order)
    params.append(QueryParam.OrderParams, encodedParam)
  }
  if (args.expiry) {
    params.append(QueryParam.SelectedExpiry, formatExpiryCode(args.expiry))
  }
  const paramsStr = params.size ? `?${params}` : ''
  return `options/${market.toLowerCase()}${paramsStr}`
}

const getPerpsPathname = (args: PageArgsMap[PageId.Perps] = {}): string => {
  const market = args.marketId ?? DEFAULT_MARKET_ID
  const params = new URLSearchParams()
  if (args.order) {
    const encodedParam = getEncodedOrderParam(args.order)
    params.append(QueryParam.OrderParams, encodedParam)
  }
  const paramsStr = params.size ? `?${params}` : ''
  return `perps/${market}${paramsStr}`.toLowerCase()
}

const getSpotPathname = (args: PageArgsMap[PageId.Spot] = {}): string => {
  const collateral = args.marketId
  const params = new URLSearchParams()
  if (args.order) {
    const encodedParam = getEncodedOrderParam(args.order)
    params.append(QueryParam.OrderParams, encodedParam)
  }
  const paramsStr = params.size ? `?${params}` : ''
  return `spot/${collateral}${paramsStr}`.toLowerCase()
}

const getHistoryPathname = (args: PageArgsMap[PageId.History]) => {
  return 'history' + (args.tab ? `?tab=${args.tab?.toLowerCase()}` : '')
}

const getMigrationDelegatePathname = (args: PageArgsMap[PageId.DelegateMigration]) => {
  return 'migration/delegate' + (args.amount ? `?amount=${args.amount}` : '')
}

const getPagePathname = <T extends keyof PageArgsMap>(args: PagePathArgs<T>): string => {
  const page = args.page as PageId
  switch (page) {
    case PageId.Landing:
      return ''
    case PageId.CreateWallet:
      return 'wallet/create'
    case PageId.TermsOfUse:
      return 'terms-of-use'
    case PageId.AirdropTerms:
      return 'terms-of-use/airdrop'
    case PageId.PrivacyPolicy:
      return 'privacy-policy'
    case PageId.Options:
      return getOptionsPathname(args as PageArgsMap[PageId.Options])
    case PageId.Perps:
      return getPerpsPathname(args as PageArgsMap[PageId.Perps])
    case PageId.Spot:
      return getSpotPathname(args as PageArgsMap[PageId.Spot])
    case PageId.Portfolio:
      return 'portfolio'
    case PageId.Home:
      return 'home'
    case PageId.History:
      return getHistoryPathname(args as PageArgsMap[PageId.History])
    case PageId.Developers:
      return 'developers'
    case PageId.Alpha:
      return 'alpha'
    case PageId.Stats:
      return 'stats'
    case PageId.DRV:
      return 'drv'
    case PageId.Leaderboard:
      return 'leaderboard'
    case PageId.User:
      return `user/${(args as PageArgsMap[PageId.User]).address}`.toLowerCase()
    case PageId.Earn:
      return 'earn'
    case PageId.Vault:
      return 'vault/' + (args as PageArgsMap[PageId.Vault]).id.toLowerCase()
    case PageId.Referrals:
      return 'referrals'
    case PageId.Borrow:
      return 'borrow'
    case PageId.Collateral:
      return 'token/' + (args as PageArgsMap[PageId.Collateral]).collateralId.toLowerCase()
    case PageId.Rfq:
      return 'rfq'
    case PageId.Balances:
      return 'balances'
    case PageId.Partners:
      return 'partners'
    case PageId.Positions:
      return 'positions'
    case PageId.Amberdata:
      return 'amberdata'
    case PageId.Trades:
      return 'trades'
    case PageId.Airdrop:
      return 'airdrop'
    case PageId.Claim:
      return 'airdrop/claim'
    case PageId.Migration:
      return 'migration'
    case PageId.DelegateMigration:
      return getMigrationDelegatePathname(args as PageArgsMap[PageId.DelegateMigration])
    case PageId.Delegate:
      return 'delegate'
    case PageId.Stake:
      return 'stake'
    case PageId.Deposits:
      return 'deposits'
    case PageId.Airdrop:
      return 'airdrop'
  }
}

export const getPagePath = <T extends keyof PageArgsMap>(args: PagePathArgs<T>): Pathname => {
  const pathname = getPagePathname(args)
  return `/${pathname}`
}
